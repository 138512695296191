.card-course {
  background: #fffdec;
  border-radius: 20px;
  text-align: center;
  margin-top: 112px;
  padding-bottom: 24px;
  img {
    margin-top: -64px;
  }
  .title-card {
    margin-top: 12px;
    font-weight: 700;
    font-size: 35px;
    line-height: 46px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title-blue {
    color: #22779b;
  }
  .title-red {
    color: #be433e;
  }
  .btn-red {
    border: #912330 1px solid;
    color: #be433e;
  }
  .btn-blue {
    border: #0a5373 1px solid;
    color: #0a5373;
  }
  .btn-transparent:hover {
    background-color: hsla(0, 0%, 0%, 0.1);
  }

  @media only screen and (max-width: 1200px) {
    .title-card {
      height: auto;
    }
  }

  @media only screen and (max-width: 400px) {
    .title-card {
      font-size: 25px;
      line-height: 30px;
      height: auto;
    }
  }
}
