.gallery {
  .img-background {
    background: linear-gradient(90deg, rgba(34, 119, 155, 0.8), rgba(34, 119, 155, 0.8)),
      url('../../../public/img/backgrounds/photos-surf-perspective.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
  }
  .color-back {
    background: #094964;
    .container {
      padding: 48px 0px;

      .img-square {
        width: 31%;
        margin: 1% 1%;
      }
      .img-rect {
        width: 64%;
        margin: 1% 1%;
      }
      .div-btn-more {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            margin-left: 10px;
          }
        }
      }
      .block-none {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .gallery {
    .img-background {
      background: linear-gradient(90deg, rgba(34, 119, 155, 0.8), rgba(34, 119, 155, 0.8)),
        url('../../../public/img/backgrounds/photos-surf-perspective-mobile.webp');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 100vh;
    }

    .color-back {
      .container {
        .img-square {
          width: 48%;
          margin: 1% 1%;
        }
        .img-rect {
          width: 98%;
          margin: 1% 1%;
        }
      }
    }
  }
}
