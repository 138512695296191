.navbar {
  background-color: hsla(198, 64%, 37%, 0.9);

  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0 5px 0;
  position: fixed;
  width: 100%;

  z-index: 10;

  .logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      filter: invert(1);
      height: 32px;
      width: auto;
    }
  }

  .menu {
    ul {
      display: flex;
      li {
        font-size: 25px;
        line-height: 33px;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin: 0 10px;
        span {
          text-align: center;
        }

        .div-sub-menu {
          .flag {
            width: 21px;
          }

          .formule {
            display: flex;
            align-items: center;
            img {
              margin-left: 5px;
            }
          }
          .sub-menu {
            display: none;

            ul {
              display: none;
              li {
                display: none;
              }
            }
          }
        }
      }

      li:hover {
        color: rgba(255, 255, 255, 0.8);

        .div-sub-menu {
          .sub-menu {
            display: block;
            ul {
              display: block;
              position: absolute;
              background: #22779b;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 8px;
              padding: 15px 5px;

              li {
                display: block;
                span {
                  padding: 0 10px;
                }
              }
              li:hover {
                background: rgba(255, 255, 255, 0.2);
                border-radius: 20px;
              }
            }

            .flags {
              display: flex;
              margin: 2px;
              padding: 5px 5px;
              li {
                display: block;
                margin: 0 2px;
                span {
                  padding: 5px 5px;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }

  .reserve {
    div {
      background: #e66e5c;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
      padding: 5px 20px;
      border-radius: 28px;
      cursor: pointer;
      span {
        font-weight: 700;
        font-size: 30px;
        line-height: 39px;
      }
    }
    div:hover {
      background: hsla(8, 73%, 63%, 0.95);
      color: hsla(0, 0%, 100%, 0.95);
    }
  }
  .btn-burger {
    display: none;
  }

  .div-reserve {
    display: none;
  }

  .div-img-social {
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
    }
  }

  .networks-display {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .navbar {
    display: block;
    .responsive {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;

      .logo {
        img {
          height: 32px;
          width: auto !important;
        }
      }
      .div-reserve {
        width: 50%;
        display: flex;
        justify-content: center;

        .btn-reserve {
          border: white 1px solid;

          font-weight: 700;
          font-size: 25px;
          line-height: 33px;
          text-align: center;
          color: #ffffff;

          background-color: rgba(255, 255, 255, 0);
          border-radius: 25px;
          padding: 5px 20px;

          display: flex;
          align-items: center;

          cursor: pointer;

          span {
            background-color: #e66e5c;
            border-radius: 10px;
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-right: 5px;
          }
        }

        .language-choice-mobile {
          position: relative;
          margin-right: 3px;
          .sub-menu {
            display: none;
          }
          .div-img-social {
            width: 25px;
            height: 20px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            margin: 12px 0 0px 5px;
            cursor: pointer;
            img {
              width: 20px;
            }
          }
        }

        .language-choice-mobile:hover,
        .sub-active {
          .sub-menu {
            display: block;
            position: inherit;
            ul {
              display: block;
              position: absolute;
              background: #22779b;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 8px;
              padding: 15px 5px;

              li {
                display: block;
                cursor: pointer;
                span {
                  padding: 0 10px;
                }
              }
              li:hover {
                background: rgba(255, 255, 255, 0.2);
                border-radius: 20px;
              }
            }

            .flags {
              display: flex;
              margin: 2px;
              padding: 5px 5px;
              li {
                display: block;
                margin: 0 2px;
                span {
                  padding: 5px 5px;
                  display: flex;
                  align-items: center;
                  img {
                    width: 20px;
                  }
                }
              }
            }
          }
        }
      }

      .btn-reserve:hover {
        background-color: hsla(0, 0%, 100%, 0.2);
      }

      .btn-burger {
        display: block;
        width: 25%;
        text-align: end;
        cursor: pointer;
      }
    }
    .reserve {
      display: none;
    }

    .menu {
      width: 100%;
      ul {
        display: block;
        li {
          justify-content: center;
          margin: 0 0px;
          padding: 5px 0;
          .div-sub-menu {
            width: 100%;
            .formule {
              display: flex;
              justify-content: center;
              margin-bottom: 5px;
              .div-img-social {
                width: 40px;
                height: 40px;
                margin: 0 10px;
                background-color: hsla(0, 0%, 100%, 0.2);
                border-radius: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                .img-social {
                  width: 20px;
                  height: 20px;
                  margin-right: 5px;
                }
              }
              .div-img-social:hover {
                background: rgba(255, 255, 255, 0.4);
              }
              .language-choice-desktop {
                display: none;
              }
            }
          }
        }

        li:hover,
        .sub-active {
          color: rgba(255, 255, 255, 0.8);
          .div-sub-menu {
            width: 100%;
            text-align: center;
            .sub-menu {
              display: block;
              ul {
                display: block;
                position: initial;
                background: rgba(255, 255, 255, 0.2);
                border-radius: 0;
                box-shadow: none;
                padding: 0px;

                li {
                  display: block;
                  span {
                    padding: 0 10px;
                  }
                }
                li:hover {
                  border-radius: 0px;
                }
              }

              .flags {
                display: none;
              }
            }
          }
        }
      }
    }

    .menu-hidden {
      display: none;
    }

    .networks-display {
      display: flex;
    }
  }
}

@media only screen and (max-width: 400px) {
  .navbar {
    .responsive {
      .logo {
        img {
          width: 65px;
        }
      }

      .div-reserve {
        .btn-reserve {
          font-size: 15px;
          border-radius: 25px;
          padding: 3px 12px;
        }
        .language-choice-mobile {
          .sub-menu {
            display: none;
          }
          .div-img-social {
            margin: 10px 0 0px 5px;
          }
        }
      }

      .btn-burger {
        img {
          width: 40px;
        }
      }
    }
  }
}
