.values {
  .img-background {
    background: linear-gradient(90deg, rgba(34, 119, 155, 0.8), rgba(34, 119, 155, 0.8)),
      url('../../../public/img/backgrounds/ecole-surf-responsable.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
  }
  .values-sentence {
    margin: 48px 0;
    font-style: italic;
    font-size: 30px;
    line-height: 39px;
    text-align: center;
    color: #0a5373;
  }
  .values-history {
    background: #0a5373;
    padding: 48px 10px;
    .block {
      display: flex;
      align-items: center;
      width: 100%;
      .block-img {
        margin: 10px;
        border: white solid 1px;
        border-radius: 30px;
        width: 50%;
        img {
          padding: 10px 10px 3px 10px;
          border-radius: 30px;
          width: 100%;
        }
      }
      .block-text {
        margin: 24px 10px;
        width: 50%;
        .description-global {
          color: white;
          text-align: justify;
        }
      }
    }

    .reverse {
      flex-direction: row-reverse;
    }
  }
  .partners {
    padding: 48px 20px;
    .block {
      margin: 24px 0px;
      display: flex;
      .block-img {
        margin-right: 24px;
        img {
          width: 200px;
        }
      }
      .block-text {
        .title-group {
          a {
            display: none;
          }
          .sub-title {
            font-size: 50px;
            line-height: 65px;
            color: #0a5373;
          }
        }
        .description-global {
          text-align: justify;
          .sub-title {
            font-weight: 700;
            font-size: 25px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
  .site-eco {
    background: linear-gradient(0deg, rgba(87, 141, 84, 0.85), rgba(87, 141, 84, 0.85)),
      url('../../../public/img/pages/valuesAndPartners/site-eco.webp');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    .container {
      padding: 48px 20px;
      .description-global {
        margin-top: 36px;
        color: white;
        p {
          margin-top: 24px;
        }
        a {
          color: white;
        }
        a:hover {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
  .partners-logo {
    padding: 48px 10px;
    .title-blue-global {
      padding: 0 10px;
      text-align: center;
    }
    .logos {
      display: flex;
      .part1 {
        width: 60%;
        display: flex;
        justify-content: center;
        .logo {
          width: 33%;
          padding: 0 10px;
          img {
            width: 100%;
          }
        }
      }
      .part2 {
        width: 40%;
        display: flex;
        justify-content: center;
        .logo {
          width: 50%;
          padding: 0 10px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .values {
    .img-background {
      background: linear-gradient(90deg, rgba(34, 119, 155, 0.8), rgba(34, 119, 155, 0.8)),
        url('../../../public/img/backgrounds/ecole-surf-responsable-mobile.webp');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 100vh;
    }

    .values-sentence {
      margin: 48px 20px;
      font-size: 16px;
      line-height: 21px;
    }
    .values-history {
      background: #0a5373;
      padding: 48px 10px;
      .block {
        display: block;
        .block-img {
          width: initial;
        }
        .block-text {
          width: 95%;
          margin: 24px 2.5%;
        }
      }
    }
    .partners {
      .block {
        width: 100%;
        .block-img {
          display: none;
        }
        .block-text {
          .title-group {
            display: flex;
            align-items: center;
            a {
              display: block;
              width: 20%;
              margin-right: 2%;
              img {
                width: 100%;
              }
            }
            .sub-title {
              width: 78%;
              font-size: 25px;
              line-height: 32px;
            }
          }
          .description-global {
            .sub-title {
              font-size: 18px;
              text-align: left;
            }
          }
        }
      }
    }
    .site-eco {
      background-size: auto;
    }
    .partners-logo {
      .logos {
        display: block;
        margin-top: 12px;
        .part1 {
          width: 100%;
          .logo {
            width: 33%;
            padding: 0 10px;
            img {
              width: 100%;
            }
          }
        }
        .part2 {
          width: 100%;
          .logo {
            width: 33%;
            padding: 0 10px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
