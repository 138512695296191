.form-booking-choice {
  .title-options {
    background-color: #094964;
    text-align: center;
    padding: 48px 48px 0 48px;
    color: #fffdec;
    line-height: 60px;
    font-size: 60px;
  }
  .choice {
    background-color: #094964;
    padding: 12px 48px 0 48px;
    .img-choice {
      display: flex;
      justify-content: center;
      img {
        width: 40%;
      }
    }
    .choices {
      display: flex;
      justify-content: center;
      padding-bottom: 48px;
      .block-hand {
        text-align: center;
        width: 50%;
        img {
          width: 270px;
        }
      }
      .block {
        position: relative;
        z-index: 1;
        border-radius: 16px;
        border: none;
        padding: 24px;
        margin: 0px 24px;
        margin-top: -105px;
        text-align: center;
        font-size: 11px;
        display: flex;
        align-items: center;
        background-color: #fffdec;
        min-height: 190px;
        .texts {
          margin: auto;
          .main {
            margin-top: 12px;
            font-size: 16px;
          }
          .italic {
            font-style: italic;
          }
          .book {
            padding: 12px;
            display: flex;
            justify-content: center;
            button,
            .select-custom {
              border: none;
              padding: 8px 16px;
              border-radius: 16px;
              color: white;
              font-weight: 600;
              cursor: pointer;
            }
            .red-btn {
              background: #193564;
            }
            .blue-btn {
              background: #8c170f;
              display: flex;
              justify-content: space-between;
              img {
                margin-left: 8px;
                width: 11px;
              }
            }
            .red-btn:hover {
              background: hsla(218, 60%, 25%, 0.8);
            }
            .blue-btn:hover {
              background: hsla(4, 81%, 30%, 0.8);
            }
          }
          .center-ul {
            display: flex;
            justify-content: center;
            ul {
              display: none;
              position: absolute;
              background: hsla(4, 81%, 30%, 0.8);
              list-style-type: none;
              border: none;
              border-radius: 16px;
              color: white;
              font-weight: 600;
              cursor: pointer;
              margin-top: -10px;
              z-index: 2;
              li {
                padding: 6px 16px;
                border-radius: 16px;
              }
              li:first-child {
                padding-top: 12px;
              }
              li:last-child {
                padding-bottom: 12px;
              }
              li:hover {
                background: hsla(4, 80%, 30%, 0.9);
              }
            }
            .show-ul {
              display: block;
            }
          }
        }
      }
      .blue-one {
        color: rgba(26, 54, 102, 0.8);
      }
      .red-one {
        color: hsla(2, 81%, 56%, 1);
      }
    }
  }
  .separator {
    background-color: #094964;
    display: flex;
    justify-content: center;
    padding: 24px;
    span {
      width: 30%;
      height: 2px;
      background-color: #1b698a;
    }
  }
}

@media only screen and (max-width: 1350px) {
  .form-booking-choice {
    .choice {
      .choices {
        .block {
          min-height: 220px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .form-booking-choice {
    .title-options {
      padding: 24px 12px 0 12px;
      line-height: 48px;
      font-size: 48px;
    }
    .choice {
      padding: 24px 12px 0 12px;
      .img-choice {
        display: none;
      }
      .choices {
        display: block;
        .block-hand {
          width: 100%;
          text-align: center;
          img {
            display: none;
          }
        }
        .block {
          position: initial;
          margin-top: 0px;
          text-align: center;
          display: flex;
          justify-content: center;
          margin: 12px 0;
          min-height: initial;
          .texts {
            .book {
              padding: 12px;
              button,
              select {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
}
.form {
  background-color: #094964;
  .container {
    padding: 28px 20px;
    display: flex;
    align-items: center;
    .block {
      padding: 10px;
      width: 50%;
      .title-global {
        color: #fffdec;
        line-height: 60px;
      }
      .book-informations {
        font-size: 13px;
        color: #fffc;
        font-style: italic;
        a {
          text-decoration: underline;
        }
      }
      .description-global {
        color: rgba(255, 255, 255, 0.6);
        margin-bottom: 36px;
        font-size: 30px;
        line-height: 39px;
      }

      a {
        text-decoration: none;
        color: white;
        .coords {
          display: flex;
          align-items: center;
          font-size: 30px;
          line-height: 39px;
          margin-top: 12px;
        }
      }
      img {
        width: 35px;
        height: 35px;
        margin-right: 30px;
      }
      .notMr30 {
        margin-right: 0px;
      }
      .social-networks {
        margin-top: 48px;
        img:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }

      .error-msg {
        color: rgb(181, 51, 51);
        font-size: 12px;
        font-weight: 700;
        opacity: 0.7;
      }
      .form {
        background: #fffdec;
        border-radius: 20px;
        padding: 20px 20px;
        .block-one,
        .block-two {
          width: 100%;
          display: flex;

          .label-infos {
            display: flex;
            align-content: center;
            .btn-infos {
              border: solid 2px #0a5373;
              width: 18px;
              height: 18px;
              border-radius: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #0a5373;
              font-weight: 700;
              font-size: 12px;
              cursor: pointer;
              margin-left: 5px;
            }
          }
          .infos-text {
            color: #0a5373;
            font-size: 10px;
            position: absolute;
            background: white;
            padding: 5px;
            border-radius: 5px;
            margin-top: 21px;
          }
          .input {
            margin-top: 12px;
            padding: 0px 10px;
            width: 100%;
            display: inline-block;

            label {
              font-weight: 700;
              font-size: 16px;
              line-height: 21px;
              color: #0a5373;
              opacity: 0.8;
            }
            input,
            textarea,
            select {
              max-width: 100% !important;
              min-width: 100% !important;
              border: 1px solid #0a5373;
              border-radius: 10px;
              padding: 10px;
              background: none;
              color: #0a5373;
              background-color: #fffdec;
            }
            select option {
              background: none;
              color: hsla(198, 64%, 37%, 0.8);
            }
            select option:hover {
              background: none;
              color: #0a5373;
            }
          }
        }
        .block-two {
          .input {
            width: 50%;
          }
        }
        .btn {
          text-align: center;
        }
        .mail-sent {
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgb(0, 209, 0);
          font-size: 12px;
          font-weight: 700;
          margin-top: 5px;

          .span-green {
            width: 6px;
            height: 6px;
            border-radius: 10px;
            margin-right: 3px;
            background-color: #00d100;
          }
        }
        .mail-not-sent {
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgb(181, 51, 51);
          font-size: 12px;
          font-weight: 700;
          margin-top: 5px;

          .span-red {
            width: 6px;
            height: 6px;
            border-radius: 10px;
            margin-right: 3px;
            background-color: rgb(181, 51, 51);
          }
        }
      }
    }
  }
  .none-text-infos {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .form {
    .container {
      display: block;
      .block {
        width: 100%;
        .title-global {
          color: #fffdec;
          font-size: 48px;
          line-height: 60px;
          text-align: center;
        }
        .book-informations {
          text-align: center;
        }
        .description-global {
          color: rgba(255, 255, 255, 0.6);
          margin-bottom: 36px;
          font-size: 16px;
          line-height: 21px;
          text-align: center;
        }
        a {
          .coords {
            justify-content: center;
            font-size: 20px;
            line-height: 26px;
            p {
              width: 220px;
            }
          }
        }
        .social-networks {
          display: flex;
          justify-content: center;
          margin-bottom: 24px;
          margin-top: 24px;
        }

        .form {
          .block-two {
            display: block;
            .input {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .form-booking-choice {
    .title-options {
      line-height: 30px;
      font-size: 30px;
    }
  }
}
