.trip {
  .img-background {
    background: linear-gradient(90deg, rgba(34, 119, 155, 0.8), rgba(34, 119, 155, 0.8)),
      url('../../../public/img/backgrounds/voyage-surf.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
  }
  .container {
    padding: 24px 20px 24px 20px;
    .block-reverse {
      flex-direction: row-reverse;
    }
    .block-travel {
      margin: 48px 0 24px 0;
      display: flex;
      align-items: center;
      img {
        width: 50%;
        margin: 0 10px;
        border-radius: 20px;
      }
      .block-description {
        width: 50%;
        margin: 0 10px;
        .title-blue-global {
          margin-bottom: 12px;
        }
        .description-global {
          margin-top: 0px;
          text-align: justify;
        }
      }
    }

    .more {
      text-align: center;
      margin-bottom: 48px;
    }
  }
}

@media only screen and (max-width: 580px) {
  .trip {
    .img-background {
      background: linear-gradient(90deg, rgba(34, 119, 155, 0.8), rgba(34, 119, 155, 0.8)),
        url('../../../public/img/backgrounds/voyage-surf-mobile.webp');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 100vh;
    }
    .container {
      .block-reverse {
        flex-direction: initial;
      }
      .block-travel {
        display: block;
        img {
          width: 95%;
          margin: 0 2.5%;
        }
        .block-description {
          width: 95%;
          margin: 0 2.5%;
          text-align: center;
          .title-blue-global {
            margin-top: 12px;
          }
          .description-global {
            margin-top: 0;
          }
        }
      }
    }
  }
}
