footer {
  background-color: #0a5373;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  justify-content: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    background-image: url('../../../../public/img/components/tools/SurfPerspectiveVertical.png');
    background-size: 200px;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 24px;
    filter: invert(1);
  }


  .social-networks {
    display: flex;
    justify-content: center;
    .social-logo {
      width: 70px;
      height: 70px;
      border-radius: 35px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 20px;
      cursor: pointer;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 35px;
        }
      }
    }
    .social-logo:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  .text-footer {
    margin-top: 24px;
    font-size: 30px;
    line-height: 39px;
    text-align: center;
    padding: 0 12px;
  }

  a {
    text-decoration: none;
    color: white;
  }
  a:hover {
    color: rgba(255, 255, 255, 0.8);
  }
}

@media only screen and (max-width: 1200px) {
  .footer {
    &::before {
      display: none;
    }
    

    .social-networks {
      .social-logo {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        margin: 0 10px;
        span {
          img {
            width: 20px;
          }
        }
      }
    }

    .text-footer {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
