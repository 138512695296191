.location {
  .img-background {
    background: linear-gradient(90deg, rgba(34, 119, 155, 0.8), rgba(34, 119, 155, 0.8)),
      url('../../../public/img/backgrounds/location-planche-surf-tarnos.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
  }
  .color-back {
    background: #094964;
    .container {
      padding: 48px 20px;
      .block-location {
        display: flex;
        align-items: center;
        .img-location {
          border: 1px solid white;
          border-radius: 30px;
          width: 50%;
          padding: 10px 10px 3px 10px;
          margin: 0 10px;
          img {
            width: 100%;
            border-radius: 20px;
          }
        }
      }
      .description-location {
        width: 50%;
        margin: 0 10px;
        .description-global {
          color: white;
          text-align: justify;
        }
      }

      .block-prices {
        padding: 48px 20px 0px 20px;
        .title-global {
          margin-bottom: 24px;
        }
      }

      .btn-middle {
        text-align: center;
        .btn-transparent {
          border: 1px solid white;
          color: white;
        }
      }
    }

    .surf {
      display: none;
    }
    .surf-wetsuit {
      display: none;
    }
    .bodyboard {
      display: none;
    }
    .body-wetsuit {
      display: none;
    }
  }
}

@media only screen and (max-width: 800px) {
  .location {
    .img-background {
      background: linear-gradient(90deg, rgba(34, 119, 155, 0.8), rgba(34, 119, 155, 0.8)),
        url('../../../public/img/backgrounds/location-planche-surf-tarnos-mobile.webp');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 100vh;
    }
    .color-back {
      .container {
        .block-location {
          display: block;
          .img-location {
            width: 98%;
            margin: 0 2%;
          }
          .description-location {
            width: 98%;
            margin: 0 2%;
          }
        }
      }
      .block-person {
        .title-price {
          font-size: 21px;
          line-height: 21px;
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .location {
    .color-back {
      .block-person {
        .title-price {
          font-size: 11px;
          line-height: 14px;
        }
      }
    }
  }
}
