.coach {
  .img-background {
    background: linear-gradient(90deg, rgba(34, 119, 155, 0.8), rgba(34, 119, 155, 0.8)),
      url('../../../public/img/backgrounds/patrick-cedras-coaching-surf-tarnos.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100dvh;
  }
  .blocks {
    display: flex;
    margin: 48px auto;
    align-items: center;
    text-align: justify;
    .img-coach {
      margin-right: 24px;
      img {
        border-radius: 20px;
      }
    }
  }
  .separator {
    height: 2px;
    margin: auto;
    width: 25%;
    background-color: rgba(34, 119, 155, 0.5);
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .more {
    padding-top: 1rem;
    .text-see-more-on-goole {
      color: #22779b;
      a {
        font-weight: bold;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .coach {
    .img-background {
      background: linear-gradient(90deg, rgba(34, 119, 155, 0.8), rgba(34, 119, 155, 0.8)),
        url('../../../public/img/backgrounds/patrick-cedras-coaching-surf-tarnos-mobile.webp');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 100vh;
    }
    .blocks {
      display: block;
      margin: 0;
      text-align: center;
      .img-coach {
        margin: 0px;
        img {
          border-radius: 0px;
          width: 100%;
        }
      }
      .description-coach {
        padding: 24px 24px 48px 24px;
      }
    }
  }
}
